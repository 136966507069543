import React from 'react';

export default function Footer() {
  return (
    <div className="footer">
      <p>Copyright © 2021 Zaige Project</p>
      <p>. All Rights Reserved. Zaige Project 版权所有</p>
    </div>
  );
}
